*
  box-sizing: border-box

$title-font: 'Lovelace', 'Georgia', serif
$sans-font: 'Standard', 'Helvetica', system-ui, sans-serif
$black: #0a0a0a
$yellow: #FFBB22

$mobilePosition: 15px
$desktopPosition: 30px

::-moz-selection
  background-color: rgba($yellow,.99)
  color: $black
::selection
  background-color: rgba($yellow,.99)
  color: $black

body
  margin: 0
  padding: 0
  border: 0
  font: inherit
  background-color: $black
  overflow: hidden
  vertical-align: baseline
  line-height: 1
  position: relative
  width: 100%
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale
  text-rendering: optimizeLegibility
  font-kerning: normal
  
  cursor: auto
  cursor: url('../assets/images/larner-logo.svg') 25 15, auto

img
  display: block
  font-size: 0
  vertical-align: top
  width: 100%

.fixed
  position: fixed
  z-index: 2

button
  appearance: none
  outline: none
  border: 0
  background-color: transparent
  padding: 0
  color: white
  font-family: $sans-font
  cursor: pointer
  line-height: 1

span.loading-fade
  position: fixed
  pointer-events: none
  top: 0
  left: 0
  right: 0
  bottom: 0
  height: 100%
  width: 100%
  background-color: $black
  z-index: 9

  html.page-loaded &
    opacity: 0
    visibility: hidden
    transition-property: opacity, visibility
    transition-duration: .75s
    transition-timing-function: ease-out
    transition-delay: .5s

  html.loading-complete &
    z-index: -9
    display: none

main.landing
  width: 100%
  height: 100%
  min-height: 100vh
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  position: relative
  padding: 60px

.landing-content
  max-width: 350px
  width: 100%
  pointer-events: none
  position: relative

h1
  font-family: $title-font
  font-weight: 300
  font-size: 3rem
  line-height: 1.15em
  letter-spacing: -1.5px
  color: white
  margin: 0
  padding: 0

span.js-text
  position: relative
  font-family: $sans-font
  padding: 0 2px
  &:last-of-type
    margin-left: -2px

  &.message
    font-family: $title-font

span.inline
  display: inline

  &.kern
    margin-left: -3.5px
    &.large
      margin-left: -6px

span.strike
  display: inline-block

span.strike:before
  content: " "
  position: absolute
  display: block
  width: 100%
  height: 4px
  background-color: $yellow
  top: 55%
  transform: translate3d(0,-55%,0)
  left: 0
  pointer-events: none

span.sans-serif
  font-family: $sans-font
  color: white
  font-size: .925rem
  font-weight: 700
  line-height: 1.2em

// button
.button
  text-decoration: none
  font-family: $sans-font
  color: white
  font-size: .925rem
  font-weight: 700

  &:after
    content: " "
    display: block
    height: 2px
    width: 0
    background-color: white
    position : absolute
    bottom: -4px
    right: 0
    transition: width .35s ease-out

  &:hover
    &:after
      left: 0
      width: 100%

.corner-btn
  bottom: $mobilePosition
  right: $mobilePosition
  @media screen and (min-width: 520px)
    bottom: $desktopPosition
    right: $desktopPosition

  &.clear-images
    bottom: auto
    top: $mobilePosition
    opacity: 0
    pointer-events: none
    transition-property: visibility, opacity
    transition: .25s ease-out
    @media screen and (min-width: 520px)
      bottom: auto
      top: $desktopPosition

    &.active
      opacity: 1
      pointer-events: auto


// prompt
.prompt
  display: flex
  flex-direction: row
  top: $mobilePosition
  left: $mobilePosition
  @media screen and (min-width: 520px)
    top: $desktopPosition
    left: $desktopPosition

span.prompt-line
  position: relative
  display: inline-block
  width: 2px
  height: 16px
  background-color: white
  animation: blink 1s infinite
  animation-play-state: running
  animation-fill-mode: forwards
  top: 0px

  .prompt.pause-animation &
    animation: none

@keyframes blink
  from, to
    opacity: 0
  50%
    opacity: 1

// index text
.title
  left: $mobilePosition
  bottom: $mobilePosition
  @media screen and (min-width: 520px)
    left: $desktopPosition
    bottom: $desktopPosition

  max-width: 250px
  width: 100%
  @media screen and (min-width: 520px)
    max-width: 100%
    width: auto
  span
    font-family: $sans-font
    color: white

.index-title, .index-description
  display: block

.index-title
  position: relative

// landing image
.landing-image
  position: absolute
  display: block
  transform-origin: center center
  animation: fadeImage .2s ease-out 1
  animation-fill-mode: forwards

.landing-image.wide
  max-width: 340px
  width: 100%

  @media screen and (min-width: 520px)
    max-width: 400px

.landing-image.tall
  max-width: 290px
  width: 100%

  @media screen and (min-width: 520px)
    max-width: 350px

// fade out landing image
.landing-image.fade-out
  animation: fadeImageOut ease-out 1
  animation-fill-mode: forwards
  animation-duration: .5s

// animation for click to add image
@keyframes fadeImage
  0%
    opacity: 0
  100%
    opacity: 1

@keyframes fadeImageOut
  0%
    opacity: 1
  100%
    opacity: 0